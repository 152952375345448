/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AllSchoolsSubscriptionPaymentsResponse } from '../models/AllSchoolsSubscriptionPaymentsResponse';
import type { BlockSubscriptionRequest } from '../models/BlockSubscriptionRequest';
import type { ChangeSubscriptionPlanRequest } from '../models/ChangeSubscriptionPlanRequest';
import type { ExtendSubscriptionGraceExpiryRequest } from '../models/ExtendSubscriptionGraceExpiryRequest';
import type { MakeDashboardSubscriptionPaymentRequest } from '../models/MakeDashboardSubscriptionPaymentRequest';
import type { PaginatedResponseSubscriptionDataResponse } from '../models/PaginatedResponseSubscriptionDataResponse';
import type { SubscriptionDashboardData } from '../models/SubscriptionDashboardData';
import type { SubscriptionPaymentDetailsResponse } from '../models/SubscriptionPaymentDetailsResponse';
import type { SubscriptionPaymentResponse } from '../models/SubscriptionPaymentResponse';
import type { SubscriptionResponse } from '../models/SubscriptionResponse';
import type { SubscriptionSessionResponse } from '../models/SubscriptionSessionResponse';
import type { UpdateSubscriptionPricingModelRequest } from '../models/UpdateSubscriptionPricingModelRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubscriptionControllerService {
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static changeSubscriptionPricingModel({
    requestBody,
  }: {
    requestBody: UpdateSubscriptionPricingModelRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/dashboard/subscription/pricing-model',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static pauseSubscription({
    requestBody,
  }: {
    requestBody: BlockSubscriptionRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/dashboard/subscription/pause',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static extendSubscriptionGrace({
    requestBody,
  }: {
    requestBody: ExtendSubscriptionGraceExpiryRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/dashboard/subscription/extend-grace',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static changeSubscriptionPlan({
    requestBody,
  }: {
    requestBody: ChangeSubscriptionPlanRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/dashboard/subscription/change-plan',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static recordPayment({
    requestBody,
  }: {
    requestBody: MakeDashboardSubscriptionPaymentRequest;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/dashboard/subscription/record-payment',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns PaginatedResponseSubscriptionDataResponse OK
   * @throws ApiError
   */
  public static getSubscriptions({
    sessionId,
    termDefinitionId,
    countryCode,
    search,
    pricingModel,
    planType,
    status,
    pageNumber,
    pageSize = 10,
  }: {
    sessionId: string;
    termDefinitionId: 'FIRST' | 'SECOND' | 'THIRD' | 'FOURTH';
    countryCode: string;
    search?: string;
    pricingModel?:
      | 'STANDARD_PER_STUDENT'
      | 'SPLIT'
      | 'NON_STANDARD_PER_STUDENT'
      | 'FLAT_FEE_PER_TERM'
      | 'FLAT_FEE_PER_SESSION';
    planType?: 'FREE' | 'STARTER' | 'STANDARD' | 'PREMIUM';
    status?: 'ACTIVE' | 'EXPIRED';
    pageNumber?: number;
    pageSize?: number;
  }): CancelablePromise<PaginatedResponseSubscriptionDataResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dashboard/subscription',
      query: {
        sessionId: sessionId,
        termDefinitionId: termDefinitionId,
        countryCode: countryCode,
        search: search,
        pricingModel: pricingModel,
        planType: planType,
        status: status,
        page_number: pageNumber,
        page_size: pageSize,
      },
    });
  }

  /**
   * @returns SubscriptionSessionResponse OK
   * @throws ApiError
   */
  public static getSubscriptionSessions(): CancelablePromise<Array<SubscriptionSessionResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dashboard/subscription/sessions',
    });
  }

  /**
   * @returns SubscriptionPaymentDetailsResponse OK
   * @throws ApiError
   */
  public static getSessionTermPaymentDetails({
    schoolId,
    sessionId,
    termDefinitionId,
  }: {
    schoolId: string;
    sessionId: string;
    termDefinitionId: 'FIRST' | 'SECOND' | 'THIRD' | 'FOURTH';
  }): CancelablePromise<SubscriptionPaymentDetailsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dashboard/subscription/session-term-payment-details',
      query: {
        schoolId: schoolId,
        sessionId: sessionId,
        termDefinitionId: termDefinitionId,
      },
    });
  }

  /**
   * @returns string OK
   * @throws ApiError
   */
  public static getNegotiationTypes(): CancelablePromise<
    Array<
      | 'STANDARD_PER_STUDENT'
      | 'SPLIT'
      | 'NON_STANDARD_PER_STUDENT'
      | 'FLAT_FEE_PER_TERM'
      | 'FLAT_FEE_PER_SESSION'
    >
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dashboard/subscription/negotiation-types',
    });
  }

  /**
   * @returns AllSchoolsSubscriptionPaymentsResponse OK
   * @throws ApiError
   */
  public static getFinancialData({
    sessionId,
    termDefinitionId,
    countryCode,
  }: {
    sessionId: string;
    termDefinitionId: 'FIRST' | 'SECOND' | 'THIRD' | 'FOURTH';
    countryCode: string;
  }): CancelablePromise<AllSchoolsSubscriptionPaymentsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dashboard/subscription/financial-data',
      query: {
        sessionId: sessionId,
        termDefinitionId: termDefinitionId,
        countryCode: countryCode,
      },
    });
  }

  /**
   * @returns SubscriptionResponse OK
   * @throws ApiError
   */
  public static getSubscriptionDetails({
    schoolId,
    sessionId,
    termDefinitionId,
  }: {
    schoolId: string;
    sessionId: string;
    termDefinitionId: 'FIRST' | 'SECOND' | 'THIRD' | 'FOURTH';
  }): CancelablePromise<SubscriptionResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dashboard/subscription/details',
      query: {
        schoolId: schoolId,
        sessionId: sessionId,
        termDefinitionId: termDefinitionId,
      },
    });
  }

  /**
   * @returns SubscriptionDashboardData OK
   * @throws ApiError
   */
  public static getSubscriptionDashboardData({
    sessionId,
    termDefinitionId,
    countryCode,
  }: {
    sessionId: string;
    termDefinitionId: 'FIRST' | 'SECOND' | 'THIRD' | 'FOURTH';
    countryCode: string;
  }): CancelablePromise<SubscriptionDashboardData> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dashboard/subscription/dashboard-data',
      query: {
        sessionId: sessionId,
        termDefinitionId: termDefinitionId,
        countryCode: countryCode,
      },
    });
  }

  /**
   * @returns SubscriptionPaymentResponse OK
   * @throws ApiError
   */
  public static getBillingHistory({
    schoolId,
    sessionId,
  }: {
    schoolId: string;
    sessionId: string;
  }): CancelablePromise<Array<SubscriptionPaymentResponse>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dashboard/subscription/billing-history',
      query: {
        schoolId: schoolId,
        sessionId: sessionId,
      },
    });
  }
}
