// @ts-ignore
import { Notify } from '@flexisaf/flexibull2';

interface IObj {
  code: number;
  message: string;
}

export type SelectOptionType = {
  label: string;
  value: any;
  extra?: any;
  order?: any;
  iso3?: any;
} | null;

export enum PricingModelEnum {
  STANDARD_PER_STUDENT = 'STANDARD_PER_STUDENT',
  SPLIT = 'SPLIT',
  NON_STANDARD_PER_STUDENT = 'NON_STANDARD_PER_STUDENT',
  FLAT_FEE_PER_TERM = 'FLAT_FEE_PER_TERM',
  FLAT_FEE_PER_SESSION = 'FLAT_FEE_PER_SESSION',
}

export const handleError = (err: any, obj?: IObj | IObj[], show = true) => {
  // obj = { code: 404, message: "An error occured" } ||
  //  [{ code: 404, message: "An error occured" }]
  window.process = { ...window.process };
  if (process) {
    const NODE_ENV = process.env.NODE_ENV;
    if (NODE_ENV === 'development') {
      console.error(err);
    }
  }
  // eslint-disable-next-line prefer-const
  let { response, body, status } = err;
  if (body && !response) {
    response = {
      data: body,
      status: body.status || status,
    };
  }
  let msg;
  let statusCode: number;
  const constMessage =
    'Sorry, an error has occurred, Please try again or if issue persist, contact support.';
  const msgObj = {
    '404': "We can't find the resource you are looking for.",
    '400': 'Sorry, an unexpected error occurred. Please try again.',
    '600': 'Sorry, an error occurred. Please check your internet conection and try again.',
    '500':
      'Sorry Something went wrong. We have logged this error and our engineers are working to fix it as soon as possible. If you need immediate assistance, please contact our support.',
    '401': '',
    '403':
      'Sorry, You do not have a permission to access the document or program that you requested',
    '408': 'Sorry, your request took too long to process, please try again.',
    '502':
      "Sorry, we are currently experiencing a glitch with this service. Don't worry we are already aware and service will be restored as soon as possible. If you need immediate assistance, please contact our support.",
    '503':
      "Sorry, we are currently experiencing a glitch with this service. Don't worry we are already aware and service will be restored as soon as possible. If you need immediate assistance, please contact our support.",
    '504': 'Sorry, your request took too long to process, please try again.',
  };
  if (response && response instanceof Object) {
    statusCode = response?.status;
    const { data } = response;
    msg = data.message || data.error || constMessage;
    // Might fallback to this.
    // msgObj[`${statusCode}`] = msg;
  } else if (err?.name === 'ApiError') {
    statusCode = err?.status;
    msg = err?.body?.message || err?.body?.error || constMessage;
  } else {
    statusCode = 600;
  }
  if (statusCode === 409) {
    msgObj[`${statusCode}`] = msg;
  }
  if (!msgObj[`${statusCode}`]) {
    msgObj[`${statusCode}`] = constMessage;
  }
  if (obj instanceof Object) {
    // @ts-ignore
    msgObj[`${obj.code}`] = obj.message;
  }
  if (obj instanceof Array) {
    obj.forEach((x) => {
      msgObj[`${x.code}`] = x.message;
    });
  }
  if (statusCode === 401 && msgObj['401'] === '') {
    return;
  }
  if (show && statusCode !== 401) {
    Notify(msgObj[statusCode], { status: 'error', position: 'top-right' });
  }
  return { success: false, statusCode, message: msg };
};

export const file_picker_callback = (callback, value, meta, second_callback) => {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');

  input.onchange = async function (e) {
    // @ts-ignore
    const file = e?.target?.files[0];
    const reader = new FileReader();

    const dimension = await getImageDimensions(file);

    reader.onload = function () {
      const id = 'blobid' + new Date().getTime();
      // @ts-ignore
      const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
      // @ts-ignore
      const base64 = reader?.result?.split(',')[1];
      const blobInfo = blobCache.create(id, file, base64);
      blobCache.add(blobInfo);

      /* call the callback and populate the Title field with the file name */
      callback(blobInfo.blobUri(), {
        title: file.name,
        height: `${dimension?.height}px`,
        width: `${dimension?.width}px`,
      });
    };

    second_callback?.(file);
    reader.readAsDataURL(file);
  };

  input.click();
};

const getImageDimensions = async (file) => {
  const img = new Image();
  img.src = URL.createObjectURL(file);
  await img.decode();
  let width = img.width;
  let height = img.height;

  if (width > 700) {
    const reductionRatio = 700 / width;
    const newWidth = width * reductionRatio;
    const newHeight = height * reductionRatio;
    width = newWidth;
    height = newHeight;
  }

  return {
    width,
    height,
    img,
  };
};

export const findIndexInArray = (originalArray, objectToFInd, key, key2?: string | undefined) => {
  return originalArray.findIndex((item) => {
    if (key2) return item[key][key2] === objectToFInd[key][key2];
    else return item[key] === objectToFInd[key];
  });
};

export const returnUpdatedList = (newObj, oldList, key = 'id', key2 = '') => {
  const indexOfLocal = findIndexInArray(oldList, newObj, key, key2);
  if (indexOfLocal !== -1) oldList[indexOfLocal] = newObj;
  return oldList;
};

export const getUrlParam = (param: string): string | undefined => {
  const urlParams = new URLSearchParams(window.location.search);
  const value = urlParams.get(param);
  return value !== null ? value : undefined;
};
