import { Fragment, Suspense } from 'react';
import './App.css';

import { Layout } from '@flexisaf/flexibull2';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Lazy, Loadable } from 'utils/ComponentLoader';
import { Theme } from 'config/theme';
import AdminLayout from 'pages/Dashboard';
import { StyledLayout } from 'style';
import PrivateRoute from 'routes/PrivateRoute';
import usePermissions from 'general-hooks/usePermissions';
import PermissionRoute from 'routes/PermissionRoute';

// O P E N    R O U T E S
const LoginPage = Loadable(() => import('pages/Login'));
const ConfirmPasswordReset = Loadable(() => import('pages/login/ConfirmPasswordReset'));
const Unauthorized = Loadable(() => import('pages/error-pages/Unauthorized'));

// G U A R D E D    R O U T E S
const Dashboard = Lazy(() => import('pages/dashboard/index'));
const NotificationConfig = Lazy(() => import('pages/notification-configuration/index'));
const UserManagement = Lazy(() => import('pages/user-management'));
const PaymentSetting = Lazy(() => import('pages/payment-settings'));
const KycConfiguration = Lazy(() => import('pages/kyc-configuration'));
const SmsConfiguration = Lazy(() => import('pages/sms-configuration'));
const CreateRole = Lazy(() => import('pages/user-management/CreateRole'));
const SubscriptionManagement = Lazy(() => import('pages/subscription-management'));
const SchoolSubscriptionDetails = Lazy(
  () => import('pages/subscription-management/SchoolSubscriptionDetails'),
);

function App() {
  const { userPermissions } = usePermissions();

  return (
    <>
      <Fragment>
        <StyledLayout>
          <Layout theme={Theme}>
            <Router>
              <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/confirm" element={<ConfirmPasswordReset />} />
                <Route path="/reset-request" element={<ConfirmPasswordReset />} />

                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <AdminLayout />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="/dashboard"
                    element={
                      <PermissionRoute
                        permission={true}
                        component={
                          <Suspense>
                            <Dashboard />
                          </Suspense>
                        }
                      />
                    }
                  />

                  <Route
                    path="/notification-configuration"
                    element={
                      <PermissionRoute
                        permission={userPermissions.VIEW_NOTIFICATIONS}
                        component={
                          <Suspense>
                            <NotificationConfig />
                          </Suspense>
                        }
                      />
                    }
                  />
                  <Route
                    path="/user-management"
                    element={
                      <PermissionRoute
                        permission={userPermissions.VIEW_USERS}
                        component={
                          <Suspense>
                            <UserManagement />
                          </Suspense>
                        }
                      />
                    }
                  />
                  <Route
                    path="/user-management/create-role"
                    element={
                      <PermissionRoute
                        permission={userPermissions.UPDATE_ROLES}
                        component={
                          <Suspense>
                            <CreateRole />
                          </Suspense>
                        }
                      />
                    }
                  />
                  <Route
                    path="/payment-settings"
                    element={
                      <PermissionRoute
                        permission={userPermissions.VIEW_FEES}
                        component={
                          <Suspense>
                            <PaymentSetting />
                          </Suspense>
                        }
                      />
                    }
                  />
                  <Route
                    path="/kyc-configuration"
                    element={
                      <PermissionRoute
                        permission={userPermissions.VIEW_KYC}
                        component={
                          <Suspense>
                            <KycConfiguration />
                          </Suspense>
                        }
                      />
                    }
                  />
                  <Route
                    path="/subscription-management"
                    element={
                      <PermissionRoute
                        permission={userPermissions.VIEW_SUBSCRIPTIONS}
                        component={
                          <Suspense>
                            <SubscriptionManagement />
                          </Suspense>
                        }
                      />
                    }
                  />
                  <Route
                    path="/subscription-management/details"
                    element={
                      <PermissionRoute
                        permission={userPermissions.VIEW_SUBSCRIPTIONS}
                        component={
                          <Suspense>
                            <SchoolSubscriptionDetails />
                          </Suspense>
                        }
                      />
                    }
                  />
                  <Route
                    path="/sms-configuration"
                    element={
                      <PermissionRoute
                        permission={userPermissions.VIEW_SMS_CONFIG}
                        component={
                          <Suspense>
                            <SmsConfiguration />
                          </Suspense>
                        }
                      />
                    }
                  />
                </Route>
              </Routes>
            </Router>
          </Layout>
        </StyledLayout>
      </Fragment>
    </>
  );
}

export default App;
