/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSchoolGroupRequest } from '../models/CreateSchoolGroupRequest';
import type { SchoolGroupDto } from '../models/SchoolGroupDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SchoolGroupRestControllerService {
  /**
   * @returns any OK
   * @throws ApiError
   */
  public static updateSchoolGroup({
    requestBody,
  }: {
    requestBody: SchoolGroupDto;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/emis-dashboard/school-group',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static createSchoolGroup({
    schoolGroup,
    requestBody,
  }: {
    schoolGroup: CreateSchoolGroupRequest;
    requestBody?: {
      logo: Blob;
    };
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/emis-dashboard/school-group',
      query: {
        'school-group': schoolGroup,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns string OK
   * @throws ApiError
   */
  public static uploadLogo({
    schoolGroupId,
    requestBody,
  }: {
    schoolGroupId: string;
    requestBody?: {
      logo: Blob;
    };
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/emis-dashboard/school-group/{schoolGroupId}/uploads/logo',
      path: {
        schoolGroupId: schoolGroupId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static getSchoolGroup({
    schoolGroupId,
  }: {
    schoolGroupId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/emis-dashboard/school-group/{schoolGroupId}',
      path: {
        schoolGroupId: schoolGroupId,
      },
    });
  }
}
